//
//  Newsletter
//  ---------------------------------------------

.block.newsletter {
    margin-bottom: 0;

    .title{  display:block !important; }

    .content {
        .form.subscribe {
            display: table;
            width: 100%;
            .field.newsletter {
                .label {
                    @extend .abs-visually-hidden;
                }
                .control {
                    #newsletter {
                        border: 1px solid $footer-background-light__color;
                        background-color: transparent;
                        color: #fff;
                        font-weight: normal;
                        font-size: 12px;
                        height: 45px;
                    }
                }
            }
            .actions {
                width: 1%;
                display: table-cell;
                vertical-align: top;
                .action.subscribe {
                    text-transform: none;
                    border: 1px solid $footer-background-light__color;
                    background-color: $footer-background-light__color;
                    color: $footer-text__color;
                    padding-top: 14px;
                    padding-bottom: 13px;
                    margin-left: -1px;


                    &:hover {
                        background-color: $secondary__color;
                        color: $primary__color;
                    }
                }
            }
            .field-recaptcha {
                display: table-row;
            }
        }
    }
}
