.swatch-attribute {
    margin-top: $indent__base;
    @include max-screen($screen__l) {
        margin-top: $indent__s;
    }
}

.swatch-attribute-label {
    position: relative;
    font-size: 12px;
}

.swatch-attribute-label.required {
    padding-right: 10px;
}

.swatch-attribute-label[data-required="1"]:after {
    content: '*';
    color: red;
    position: absolute;
    right: -11px;
    top: -2px;
    font-weight: bold;
    font-size: 1em;
}

.swatch-attribute-selected-option {
    display: none;
    color: #646464;
    padding-left: 17px;
}

.swatch-attribute-options {
    margin-top: 10px;
}

.swatch-option {
    @include abs-add-box-sizing();
    height: 43px;
    width: 76px;
    float: left;
    padding: 0;
    margin: 0 8px 8px 0;
    text-align: center;
    cursor: pointer;
    position: relative;
    border: 1px solid #d0d0d0;
    overflow: hidden;
    text-overflow: ellipsis;
}

.swatch-option.text {
    background: #fbfbfb;
    color: $primary__color;
    font-size: 12px;
    line-height: 43px;
    font-weight: 400;
}

.swatch-option.selected {
    outline: 1px solid $secondary__color;
    border: 1px solid #fff;
    color: $primary__color;
}

.swatch-option.text.selected {
    background-color: #fff !important;
}

.clearfix:after {
    content: '';
    visibility: hidden;
    display: block;
    height: 0;
    clear: both;
}

.swatch-option:not(.disabled):hover {
    outline: 1px solid $secondary__color;
    border: 1px solid #fff;
    color: $primary__color;
}

.swatch-option.disabled {
    cursor: default;
    color: #a4a4a4;
    filter: grayscale(100%);
}

.swatch-option.disabled:after {
    content: '';
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    background-image: url('../images/disabled.png');
}

.swatch-option-tooltip {
    max-width: 140px;
    max-height: 100%;
    min-height: 20px;
    min-width: 20px;
    position: absolute;
    padding: 5px;
    background: #fff;
    color: rgb(148, 148, 148);
    border: 1px solid #adadad;
    display: none;
    z-index: 999;
    text-align: center;
}

.swatch-option-tooltip .corner,
.swatch-option-tooltip-layered .corner {
    left: 40%;
    position: absolute;
    bottom: 0;
    height: 8px;
}

.swatch-option-tooltip .corner:after,
.swatch-option-tooltip-layered .corner:after {
    content: '';
    position: relative;
    top: 1px;
    left: -15px;
    width: 0;
    height: 0;
    border-style: solid;
    border-width: 7px 7.5px 0 7.5px;
    border-color: #fff transparent transparent transparent;
    font-size: 1px;
}

.swatch-option-tooltip .corner:before,
.swatch-option-tooltip-layered .corner:before {
    content: '';
    position: relative;
    top: 2px;
    left: 0;
    width: 0;
    height: 0;
    border-style: solid;
    border-width: 8px 8.5px 0 8.5px;
    border-color: #adadad transparent transparent transparent;
    font-size: 1px;
}

.swatch-option-tooltip .image,
.swatch-option-tooltip-layered .image {
    display: block;
    height: 130px;
    width: 130px;
    margin: 0 auto;
}

.swatch-option-tooltip .title {
    max-width: 140px;
    min-height: 20px;
    max-height: 200px;
    color: rgb(40, 40, 40);
    text-align: center;
    display: block;
    overflow: hidden;
}

.swatch-opt {
    margin: 20px 0;
    @include max-screen($screen__l) {
        margin: $indent__s 0;
    }

}

.swatch-more {
    display: inline-block;
    margin: 2px 0;
    text-decoration: none !important;
    position: static;
    z-index: 1;
}

// Layered Features
.swatch-option-link-layered {
    position: relative;
    margin: 0 !important;
    padding: 0 !important;
}

.swatch-option-link-layered:focus > div {
    box-shadow: 0 0 3px 1px #68a8e0;
}

.swatch-option-tooltip-layered {
    width: 140px;
    position: absolute;
    background: #fff;
    color: rgb(148, 148, 148);
    border: 1px solid #adadad;
    display: none;
    z-index: 999;
    left: -47px;
}

.swatch-visual-tooltip-layered {
    height: 160px;
    top: -170px;
}

.swatch-textual-tooltip-layered {
    height: 30px;
    top: -40px;
}

.swatch-option-link-layered:hover > .swatch-option-tooltip-layered {
    display: block;
}

.swatch-attribute-options .swatch-option-tooltip-layered .title {
    width: 100%;
    height: 20px;
    position: absolute;
    bottom: -5px;
    left: 0;
    color: rgb(40, 40, 40);
    text-align: center;
    margin-bottom: 10px;
}

.swatch-option-disabled:after {
    content: '';
    position: absolute;
    width: 42px;
    height: 2px;
    background: red;
    transform: rotate(-30deg);
    z-index: 995;
    left: -4px;
    top: 10px;
}

.swatch-option-disabled {
    outline: none !important;
    cursor: default;
    border: 0;
}

// Bugfix for Add To Cart button
.swatch-opt-listing {
    margin-bottom: 10px;
}

.swatch-option-loading {
    content: url(../Magento_Swatches/images/loader-2.gif);
}

.swatch-input {
    left: -1000px;
    position: absolute;
    visibility: hidden;
}
