//
//  GDPR Cookie Compliance
//  ____________________________________________

//
//  Variables
//  _____________________________________________

$amgdprcookie-text__color: #363636;
$amgdprcookie-btn__background: #ef7c4a;
$amgdprcookie-btn__text: #fff;
$amgdprcookie-container__background: #fff;
$amgdprcookie-toogle-disable__background: #e8e8e8;
$amgdprcookie-scroll__color: #e0e0e0;
$amgdprcookie-scroll__background: $amgdprcookie-toogle-disable__background;
$amgdprcookie-toogle-normal__background: #a5a5a5;
$amgdprcookie-toogle__length: 25px;
$amgdprcookie-toogle__padding: 2px;
$amgdprcookie-toogle-checked__background: #1979c3;
$amgdprcookie-link__hover__color: #1787e0;
$amgdprcookie-link__color: #4670c9;
$amgdprcookie-link__base_color: #2977c2;
$amgdprcookie-button__focus: 0 0 3px 1px #00699d;
$amgdprcookie-btn-done__highlight: #1357e6;
$amgdprcookie-popup__shadow: #0a6c9f;
$amgdprcookie-btn-close-icon__background-image: url('data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMTciIGhlaWdodD0iMTciIGZpbGw9Im5vbmUiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyI+PGNpcmNsZSBjeD0iOC41IiBjeT0iOC41IiByPSI4IiBzdHJva2U9IiNDQUNBQ0EiLz48cGF0aCBkPSJNNS4yNSA1LjI1bDYuNSA2LjVtMC02LjVsLTYuNSA2LjUiIHN0cm9rZT0iI0NBQ0FDQSIvPjwvc3ZnPg==');

//
//  Mixins
//  _________________________

@mixin am-gdprcookie-scrollbar(
    $color: $amgdprcookie-scroll__background,
    $second-color: #fff,
    $radius: 2px,
    $width: 4px
) {
    & {
        overflow: auto;

        // Firefox Custom Scrollbar Styles
        scrollbar-color: $color $second-color;
        scrollbar-width: thin;
        // (END) Firefox Custom Scrollbar Styles

        // IE Custom Scrollbar Styles
        scrollbar-face-color: $color;
        scrollbar-highlight-color: $color;
        scrollbar-shadow-color: $color;
        scrollbar-track-color: $second-color;
        // (END) IE Custom Scrollbar Styles
    }

    // Webkit Custom Scrollbar Styles
    &::-webkit-scrollbar {
        width: $width;
    }

    &::-webkit-scrollbar-track {
        background-color: $second-color;
        box-shadow: none;
    }

    &::-webkit-scrollbar-thumb {
        border-radius: $radius;
        background: $color;
    }
    // (END) Webkit Custom Scrollbar Styles
}

//
//  GDPR Cookie Compliance Modal Styles
//  ____________________________________________

//
//  Common
//  ----------------------------------------------

.amgdprcookie-groups-modal {
    z-index: 999;

    .modal-inner-wrap {
        border-radius: 4px;
        box-shadow: 0 -2px 18px rgba(0, 0, 0, .06);
        max-width: 740px;
    }

    &.-table .modal-inner-wrap {
        max-width: 1290px;
        min-width: 700px;
        overflow-x: auto;
    }

    .modal-title {
        border-bottom: none;
        font-size: 20px;
        font-weight: bold;
    }

    .action-close:focus {
        box-shadow: $amgdprcookie-button__focus;
    }

    .amgdprcookie-form {
        padding: 0 10px;
    }

    .amgdprcookie-done {
        background-color: $amgdprcookie-link__color;
        border-radius: 3px;
        box-shadow: none;
        color: $color-white;
        font-weight: bold;
        letter-spacing: 1.35px;
        text-transform: uppercase;
        transition: background-color .3s ease-out;
        width: 100%;
    }

    .amgdprcookie-done:hover {
        background-color: $amgdprcookie-btn-done__highlight;
    }

    .amgdprcookie-done:focus {
        background-color: $amgdprcookie-btn-done__highlight;
        box-shadow: $amgdprcookie-button__focus;
    }

    &:not(.-table) .modal-footer {
        padding-top: 0;
    }
}

.amgdprcookie-groups-modal .amgdprcookie-form-container,
.amgdprcookie-groups-modal .amgdprcookie-tbody {
    @include am-gdprcookie-scrollbar($amgdprcookie-scroll__color, $color-white, 2px, 6px);
    & {
        display: block;
        max-height: 500px;
        overflow-y: auto;
    }
}

.amgdprcookie-bar-container {
    & {
        background: $color-black;
        box-shadow: 0 -2px 18px rgba(0, 0, 0, .06);
        display: flex;
        justify-content: center;
        margin: 0;
        padding: 30px 10px;
        z-index: 10;
    }

    &.modal-popup {
        bottom: unset;
        left: unset;
        overflow-y: unset;
        pointer-events: auto;
        position: unset;
        right: unset;
        top: unset;
        transition: unset;
    }

    .amgdprcookie-buttons-block {
        display: flex;
        flex-direction: column;
    }

    .amgdprcookie-policy {
        margin-bottom: 20px;
        max-height: 30vh;
        max-width: 640px;
        overflow-y: auto;
    }

    .amgdprcookie-button:first-child {
        margin-bottom: 10px;
    }

    .amgdprcookie-buttons-block .amgdprcookie-button {
        margin-bottom: 0;
        padding: 10px 30px;
    }

    .action-close {
        & {
            line-height: 14px;
            right: 10px;
            top: 10px;
        }

        &:focus,
        &:active {
            box-shadow: $amgdprcookie-button__focus;
            opacity: .9;
        }
    }
}

.amgdprcookie-buttons-block {
    & {
        display: flex;
        flex-direction: column;
    }

    &.-settings {
        padding-bottom: 30px;
    }

    .amgdprcookie-button {
        & {
            background: $color-white;
            border: none;
            border-radius: 3px;
            box-shadow: none;
            color: $amgdprcookie-link__color;
            font-weight: bold;
            letter-spacing: 1.35px;
            margin-top: 15px;
            padding: 10px 15px;
            text-transform: uppercase;
            transition: opacity .3s ease, color .3s ease-out, background-color .3s ease-out, border .3s ease-out;
        }

        &:hover {
            opacity: .9;
        }

        &:active,
        &:focus {
            box-shadow: $amgdprcookie-button__focus;
            opacity: .9;
        }

        &.-allow,
        &.-save {
            background: $amgdprcookie-link__color;
            color: $color-white;
            flex-basis: 33%;
        }
    }
}

.amgdprcookie-modal-template {
    & {
        background: $color-white;
        margin-left: 5px;
        overflow-y: auto;
    }

    .action-close {
        & {
            background: $color-white;
            line-height: 14px;
            margin: 0;
            padding: 8px;
            right: 0;
            top: 0;
            z-index: 1;
        }

        &:focus,
        &:active {
            background: $color-white;
            box-shadow: $amgdprcookie-button__focus;
            opacity: .9;
        }

        &:hover {
            background: $color-white;
        }
    }

    .amgdprcookie-button.-allow:not(.-save) {
        background: inherit;
        color: $amgdprcookie-link__color;
        margin-bottom: 0;
    }
}

.amgdprcookie-modal-container .modal-content {
    padding: 0;
}

.amgdprcookie-groups-container {
    box-shadow: 0 -2px 18px rgba(0, 0, 0, .06);
    direction: rtl;
    height: auto;
    overflow-y: scroll;
}

.amgdprcookie-policy-container {
    bottom: 0;
    box-shadow: 0 -2px 18px rgba(0, 0, 0, .06);
    padding: 24px;
    position: absolute;
}

.amgdprcookie-text-container .amgdprcookie-policy {
    display: inline;
}

.amgdprcookie-modal-container.modal-popup.modal-slide {
    left: 0;
    pointer-events: none;
}

.amgdprcookie-modal-container .modal-inner-wrap {
    & {
        height: 100%;
        margin: 0;
        position: relative;
    }

    .modal-header {
        position: absolute;
        right: 0;
    }

    .action-close {
        & {
            margin: 5px;
            padding: 0;
        }

        &:before {
            background: $amgdprcookie-btn-close-icon__background-image no-repeat center;
            content: '';
            height: 25px;
            width: 25px;
        }

        &:focus {
            box-shadow: $amgdprcookie-button__focus;
        }
    }

    .amgdprcookie-groups-container {
        @include am-gdprcookie-scrollbar();
    }
}

.amgdprcookie-settings-form .amgdprcookie-toggle-cookie .amgdprcookie-input.disabled + .amgdprcookie-label {
    background: $amgdprcookie-toogle-disable__background;
}

.amgdprcookie-groups-modal {
    .amgdprcookie-table .amgdprcookie-row,
    .amgdprcookie-table thead tr,
    .amgdprcookie-table thead {
        display: table;
        table-layout: fixed;
        width: 100%;
    }

    .amgdprcookie-cell.-name {
        word-wrap: break-word;
    }
}

.amgdprcookie-modal-container .amgdprcookie-modal-template {
    height: 100%;
    margin-left: 0;
    min-width: 400px;
    pointer-events: auto;
    position: relative;
    width: 20%;
    z-index: 900;
}

.ammodals-overlay {
    background-color: rgba(51, 51, 51, .55);
    bottom: 0;
    left: 0;
    position: fixed;
    right: 0;
    top: 0;
    z-index: 899;
}

//
//  Mobile
//  ----------------------------------------------

@include max-screen($screen__m) {
    .amgdprcookie-groups-modal {
        &.-table .modal-inner-wrap {
            min-width: 95%;
        }

        .amgdprcookie-table {
            font-size: 1rem;
        }
    }

    .modal-popup .modal-content {
        padding-left: 1rem;
        padding-right: 1rem;
    }

    .amgdprcookie-bar-container {
        .action-close {
            padding: 10px;
            right: 0;
            top: 0;
        }
    }

    .amgdprcookie-modal-container .amgdprcookie-modal-template {
        min-width: unset;
        width: unset;
    }
}

//
//  Tablet
//  ----------------------------------------------

@include min-screen($screen__m) {
    .amgdprcookie-groups-modal {
        .amgdprcookie-cookie-container,
        .amgdprcookie-header,
        .amgdprcookie-text {
            padding: 0;
        }
    }

    .amgdprcookie-modal-container .modal-inner-wrap {
        background: $color-white;
        min-width: 400px;
        right: unset;
        width: 21%;
    }

    .amgdprcookie-table .amgdprcookie-cell.desc,
    .amgdprcookie-table .amgdprcookie-title.-wide {
        width: 30%;
    }

    .amgdprcookie-table .amgdprcookie-cell,
    .amgdprcookie-table .amgdprcookie-title {
        width: 15%;
    }

    .amgdprcookie-bar-container .amgdprcookie-buttons-block {
        & {
            flex-direction: row;
            margin-bottom: 5px;
        }

        .amgdprcookie-button {
            margin-bottom: 0;
        }
    }
}

//
//  Desktop
//  ----------------------------------------------

@include min-screen($screen__l) {
    .amgdprcookie-groups-modal .amgdprcookie-form {
        padding: 0 20px;
    }

    .checkout-index-index {
        .modal-popup.amgdprcookie-modal-container {
            .modal-inner-wrap {
                left: unset;
                margin-left: unset;
                width: 21%;
            }
        }
    }
}


//
//  GDPR Cookie Compliance
//  ____________________________________________

//
//  Common
//  ----------------------------------------------

.amgdprcookie-cookie-container {
    & {
        margin: 0 10px 20px 0;
        padding: 0 0 25px 0;
    }

    &.-modal:first-child {
        padding-top: 25px;
        margin-top: 45px;
    }

    &.-modal .amgdprcookie-header,
    &.-modal .amgdprcookie-text {
        padding-left: 25px;
    }

    &.-modal {
        box-shadow: none;
        direction: ltr;
        margin-right: 30px;
    }

    .amgdprcookie-header {
        display: flex;
        justify-content: space-between;
    }

    .amgdprcookie-caption {
        font-size: 16px;
        font-weight: bold;
        line-height: 27px;
        margin: 0 0 15px;
    }

    .amgdprcookie-text {
        color: $amgdprcookie-text__color;
        font-size: 12px;
        margin-bottom: 10px;
    }

    .amgdprcookie-options {
        color: $amgdprcookie-link__color;
        cursor: pointer;
        font-size: 12px;
    }
}

.amgdprcookie-toggle-cookie {
    & {
        padding-left: 50px;
        position: relative;
    }

    .amgdprcookie-input {
        height: 0;
        opacity: 0;
        width: 0;
    }

    .amgdprcookie-label {
        & {
            background: $amgdprcookie-toogle-normal__background;
            border-radius: 40px;
            box-shadow: 0 2px 5px rgba(0, 0, 0, .05);
            cursor: pointer;
            height: 29px;
            position: absolute;
            right: 0;
            top: 0;
            width: 50px;
        }

        &:after {
            background: $amgdprcookie-container__background;
            border-radius: 40px;
            content: '';
            height: $amgdprcookie-toogle__length;
            left: $amgdprcookie-toogle__padding;
            position: absolute;
            top: $amgdprcookie-toogle__padding;
            transition: all .4s ease;
            width: $amgdprcookie-toogle__length;
        }
    }

    .amgdprcookie-input:active + .amgdprcookie-label,
    .amgdprcookie-input:focus + .amgdprcookie-label {
        box-shadow: $amgdprcookie-button__focus;
    }

    .amgdprcookie-input:checked:disabled + .amgdprcookie-label {
        & {
            background: $amgdprcookie-toogle-disable__background;
        }

        &:after {
            left: 95%;
            transform: translateX(-100%);
        }
    }

    .amgdprcookie-input:checked:not([disabled]) + .amgdprcookie-label {
        & {
            background: $amgdprcookie-toogle-checked__background;
        }

        &:after {
            left: 95%;
            transform: translateX(-100%);
        }
    }

    .amgdprcookie-input:not([checked]) + .amgdprcookie-label {
        background: $amgdprcookie-toogle-normal__background;
    }

    .amgdprcookie-input:disabled + .amgdprcookie-label {
        & {
            background: $amgdprcookie-toogle-disable__background;
        }

        &:after {
            left: $amgdprcookie-toogle__padding;
        }
    }

    .amgdprcookie-input:focus + .amgdprcookie-label {
        box-shadow: $amgdprcookie-button__focus;
    }
}

.amgdprcookie-main-wrapper {
    & {
        color: $amgdprcookie-text__color;
    }

    .amgdprcookie-top {
        display: flex;
        justify-content: space-between;
        margin-bottom: 15px;
    }

    .amgdprcookie-head {
        font-size: 20px;
        line-height: 20px;
    }

    .amgdprcookie-btn-wrapper {
        display: flex;
    }

    .amgdprcookie-btn {
        & {
            background: $amgdprcookie-btn__background;
            border: 0;
            border-radius: 6px;
            box-shadow: 0 3px 5px rgba(62, 40, 13, .15);
            color: $amgdprcookie-btn__text;
            font-size: 14px;
            padding: 7px 27px;
            text-transform: uppercase;
        }

        &:first-child {
            margin: 0 15px;
        }

        &:hover {
            background: darken($amgdprcookie-btn__background, 10%);
        }

        &:focus,
        &:active {
            box-shadow: $amgdprcookie-button__focus;
        }
    }
}

.amgdprcookie-cookie-container .amgdprcookie-link {
    color: $amgdprcookie-link__base_color;
    cursor: pointer;
    float: right;
    font-size: 12px;
    letter-spacing: .3px;
    text-transform: capitalize;
}

.amgdprjs-bar-template {
    & {
        display: block !important;
        position: fixed; /* added to support older browsers */
        position: sticky;
    }

    .amgdprcookie-buttons-block .amgdprcookie-button {
        margin-right: 5px;
    }
}

.amgdprjs-bar-template {
    &.-popup {
        & {
            left: 50%;
            margin: 0 auto;
            max-width: 740px;
            position: fixed;
            top: 50%;
            transform: translate(-50%, -50%);
        }

        &:before {
            background-color: $amgdprcookie-popup__shadow;
            content: '';
            height: 100%;
            width: 100%;
        }

        .amgdprcookie-bar-container {
            padding: 50px;
        }

        .amgdprcookie-bar-block {
            width: 100%;
        }
    }

    .amgdprcookie-buttons-block {
        display: flex;
        justify-content: center;
    }
}

//
//  Tablet
//  ----------------------------------------------

@include min-screen($screen__m) {
    .amgdprcookie-main-wrapper {
        & {
            padding: 0;
        }

        .amgdprcookie-top {
            margin-bottom: 35px;
        }

        .amgdprcookie-head {
            font-size: 34px;
            line-height: normal;
        }

        .amgdprcookie-btn {
            box-shadow: 0 3px 10px rgba(62, 40, 13, .15);
            font-size: 20px;
            padding: 17px 46px;
            text-transform: none;
        }
    }

    .amgdprcookie-cookie-container {
        & {
            margin-bottom: 30px;
            padding: 25px 0;
        }

        .amgdprcookie-caption {
            font-size: 20px;
            margin-bottom: 15px;
        }

        .amgdprcookie-toggle-cookie {
            padding-left: 0;
        }

        .amgdprcookie-text {
            font-size: 14px;
        }

        .amgdprcookie-options {
            background: inherit;
            display: inline-block;
            font-size: 14px;
        }
    }
}
