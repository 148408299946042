//
//  Cookie Bar
//  ---------------------------------------------

#hb-cc-wrap {
    display: none;
    padding: 10px 20px;
    text-align: center;
    background: $primary__color;
    color: #ffffff;
    border-bottom: 1px solid #223648;
    @include lib-font-size(13);

    a {
        color: #94acc6;
    }
}



//ide homepage title
.cms-index-index{
    .page-title-wrapper{
        display: none;
    }

    //
    //  Sidebar categories
    //  ---------------------------------------------

    #sidebar-categories {
        padding-top: 24px;
    }
}

//
//  New Arrivals
//  ---------------------------------------------

.block-new-products {
    .page-layout-3columns & {
        display: none;
    }
    .block-title {
        font-size: 18px;
        text-transform: uppercase;
        color: $primary__color;

        strong {
            font-weight: 500;
        }
    }
}

//
//  Category List
//  ---------------------------------------------

.category-list {
    margin-top: $indent__base;

    a {
        display: block;
        margin-bottom: $indent__l;

        img {
            display: block;
            width: 100%;
        }
        span {
            text-transform: uppercase;
            color: #ffffff;
            padding: 15px 20px;
            text-overflow: ellipsis;
            white-space: nowrap;
            overflow: hidden;
            position: relative;
            display: block;
            bottom: 0;
            /* width: 100%; */
            font-family: 'Avenir-Roman';
            border: none;
            line-height: 28px;
            left: 0;
            padding: 12px 20px !important;
            font-size: 18px !important;
            background: #1f322a;
            background: linear-gradient(90deg, #1f322a 0%, #20362d 16%, #344d42 100%);
            filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#1f322a",endColorstr="#344d42",GradientType=1);

            &:before {
                float: right;
                @extend .fas;
                content: fa-content($fa-var-chevron-right);
                font-size: 12px;
                margin-top: 4px;
                margin-left: $indent__s;
            }
        }
        &:hover {
            text-decoration: none;
        }
    }
}

//
//  Sidebar List
//  ---------------------------------------------

.sidebar-list {
    @include max-screen($screen__m) {
        display: none;
    }

    ul {
        @extend .abs-reset-list;

        > li {
            > a {
                display: block;
                color: $primary__color;

                &:hover,
                &:focus {
                    text-decoration: none;
                    color: #0960b3;
                }
            }
        }
    }
    .level1 {
        > li {
            > a {
                padding: $indent__base 0;
                border-top: 1px solid $border__color;
                text-transform: uppercase;
                font-weight: 500;

                @include max-screen($screen__m) {
                    padding: $indent__s 0;
                }
            }
            &.has-subs {
                > a {
                    &:before {
                        float: right;
                        @extend .fas;
                        content: fa-content($fa-var-chevron-down);
                        font-size: 6px;
                        margin-top: 4px;
                        transform: rotate(-90deg);
                    }
                }
            }
            &.active {
                > ul {
                    display: block;
                }
                &.has-subs {
                    > a {
                        &:before {
                            content: fa-content($fa-var-chevron-up);
                            transform: rotate(900deg);
                        }
                    }
                }
            }
            &:first-child {
                > a {
                    border: 0;
                }
            }
        }
    }
    .level2 {
        display: none;

        > li {
            > a {
                margin-bottom: $indent__base;
                font-size: 13px;
                font-weight: 300;

                @include max-screen($screen__m) {
                    margin-bottom: $indent__s;
                }
            }
        }
    }
}

//
//  Homepage Brands
//  ---------------------------------------------

.homepage-brands {
    padding: $indent__xl 0;

    @include max-screen($screen__m) {
        padding: $indent__base 0;
    }

    .brands {
        display: none;
        padding: 0 $indent__xl;

        .slick-track {
            display: table;
        }
        a {
            display: table-cell;
            vertical-align: middle;
            float: none;
            font-size: 0;
            text-align: center;

            img {
                display: inline-block;
                max-width: 80%;

                &:hover {
                    opacity: 0.9;
                }
            }
        }
    }
}

//
//  Homepage Pods
//  ---------------------------------------------

.homepage-pods {
    @extend .abs-add-clearfix;
    padding:46px 0 30px;

    @include max-screen($screen__xl) {
        padding: ($indent__base * 2) 0 ($indent__base * 2 - 7);
    }
    @include max-screen($screen__m) {
        padding: $indent__base 0 ($indent__base - 7);
    }
    .row {
        margin-right: 0;
        margin-left: -14px;
    }
    .left,
    .right {
        position: relative;
        margin-bottom: 20px;
        padding-right: 0;
        padding-left: 14px;
    }
    .left {
        float: left;
        img {
            width: 100%;
            float: left;
        }
    }
    .right {
        float: right;
        img {
            float: right;
        }
    }
    .nm {
        margin-bottom: 0;
    }
    a.action.primary.large {
        position: relative;
        bottom: 0;
        width: 100%;
        font-family: 'Avenir-Roman';
        border: none;
        line-height: 28px;
        left: 0;
        padding: 12px 20px !important;
        height: 50px;
        font-size: 18px !important;
        background: rgb(31,50,42);
        background: -moz-linear-gradient(90deg, rgba(31,50,42,1) 0%, rgba(32,54,45,1) 16%, rgba(52,77,66,1) 100%);
        background: -webkit-linear-gradient(90deg, rgba(31,50,42,1) 0%, rgba(32,54,45,1) 16%, rgba(52,77,66,1) 100%);
        background: linear-gradient(90deg, rgba(31,50,42,1) 0%, rgba(32,54,45,1) 16%, rgba(52,77,66,1) 100%);
        filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#1f322a",endColorstr="#344d42",GradientType=1);
    }

    @include max-screen($screen__l) {
        a.action.primary.large {
            padding: 15px !important;
            font-size: 12px !important;
        }
    }
}

//
//  Homepage Featured
//  ---------------------------------------------

.homepage-featured {
    background-color: #303335;
    padding: 35px 0 50px;
    margin-bottom: 48px;

    @include max-screen($screen__xl) {
        padding: 40px 0 15px;
    }
    @include max-screen($screen__m) {
        padding: 40px 0 15px;
    }
    .homepage-featured-line {
        text-align: center;
        position: relative;
        margin: 0 40px;

        &:before{
            content: ' ';
            position: absolute;
            background: #3a3d3f;
            height: 1px;
            width: 100%;
            left: 0;
            top: 8px;
        }

        h2 {
            display: inline-block;
            font-family: "Avenir-Light";
            font-size: 14px;
            font-weight: 500;
            text-transform: uppercase;
            margin: 0;
            color: #6e7375;
            padding: 0 20px;
            position: relative;
            letter-spacing: 3px;
            background: #303335;

            @include max-screen($screen__m) {
                padding: 0 $indent__s;
                font-size: 18px;
            }
        }
    }
    .block {
        margin: 0;
    }

    .slick-slide{
        & > div{
            padding: 0 15px;

            .product-item{
                max-width: 100% !important;
                &-name{
                    height: 60px;
                    @include max-screen($screen__l) {
                        height: 57px;
                    }
                }
                &-details,
                &-photo{
                    width: 100% !important;
                }
                a.action.primary.large{
                    width: 100%;
                    position: relative;
                    right: 0;
                }
            }
        }
    }
}

//
//  Homepage Focus
//  ---------------------------------------------

.homepage-newsletter{
    position: relative;
    margin-bottom: 40px;
    height: 346px;
    overflow: hidden;

    .container-fluid > img{
        position: absolute;
        left: 0;
        top: 0;
        width: 1122px;
        max-width: 1122px;
        z-index: -1;
    }
    .block.newsletter{
        float: right;
        width: 100%;
        text-align: center;
        padding-top: 101px;
        max-width: 335px;
        padding-right: 134px;

        h1,h2,h3,h4,h5,p,a{
            color: #fff;
        }
        h3{
            margin: 0 0 20px 0;
            font-size: 18px;
        }
        p{
            margin: 0 0 30px 0;
            font-size: 13px;
        }

        form{
            position: relative;

            button{
                position: absolute;
                right: 0;
                top: 0;
                border: none;
                overflow: hidden;
                text-indent: -999px;
                color: transparent;
                background-position: center;
                background-color: transparent;
                background-repeat: no-repeat;
                border: none;
                background-image: url(../images/newsletter-right.png);
            }
        }
    }
}

@include max-screen($screen__m) {
    .homepage-newsletter{
        .block.newsletter{
            padding-right: 0;
            margin: 0 auto;
            float: none;
        }
    }
}
