@import 'module/listings';
@import 'module/toolbar';
@import 'module/gallery';
@import 'module/gallery360';

.loading-mask {
    display: none;
}

.added-to-basket {
    font-weight: bold;
    color: green;
    margin-top: $indent__base;
    font-size: 16px;
}

.call-us {
    font-size: 16px;
    margin: $indent__l 0;

    p {
        margin: 0;
    }
}

.fotorama__caption {
    display: none;
}

.description-above-pricing {
    margin-top: $indent__base;
}

#sidebar-categories {

    &.sidebar-list .level1 > li:not(:first-child) > a {
        border-top: 1px solid #292d2f;
    }
    &.sidebar-list .level1 > li > a {
        color: #ffffff;

        &:hover,
        &:active,
        &.active {
            color: #ffffff;
        }
    }

    &.sidebar-list .level2 > li > a {
        color: #8d8e94;
        font-size: 14px;
    }
}

//
//  Product Upsell
//  ---------------------------------------------

.block.upsell {
    background-color: #303335;
    padding: 35px 25px 50px 15px;
    margin-bottom: 48px;

    .products-grid .product-items {
        padding: 0 40px;
        position: relative;
    }

    .block-title.title {
        text-align: center;
        position: relative;
        margin: 0 40px;

        &:before {
            content: ' ';
            position: absolute;
            background: #3a3d3f;
            height: 1px;
            width: 100%;
            left: 0;
            top: 8px;
        }
        #block-upsell-heading {
            display: inline-block;
            font-family: "Avenir-Light";
            font-size: 14px;
            font-weight: 500;
            text-transform: uppercase;
            margin: 0;
            color: #6e7375;
            padding: 0 20px;
            position: relative;
            letter-spacing: 3px;
            background: #303335;
        }
    }
    .slick-slider .slick-arrow.slick-next {
        right: 5px;
    }
    .slick-slide {
        float: left !important;

        & > div {
            padding: 0 15px;
        }
    }

    .product-item{
        max-width: 100% !important;

        .product-item-name {
            height: 60px;
        }
        .product-item-details,
        .product-item-photo {
            width: 100% !important;
        }
        .product-item-info {
            position: relative;
            padding: 20px 10px 60px 10px;
        }
        a.action.primary.large {
            bottom: -1px;
            left: -1px;
            position: absolute;
            width: calc(100% + 2px);
        }
    }


}

//
//  Product Page
//  ---------------------------------------------
#media-main-wrapper{
    background: #303335;
    padding: 50px;

    @include max-screen($screen__xs) {
        padding: 10px;
    }
    @include max-screen($screen__s) {
        padding: 15px;
    }
    @include max-screen($screen__m) {
        padding: 20px;
    }

    @include lib-clearfix();

    .additional-attributes {
        width: 100%;

        tr {
            border-bottom: 1px solid #505154;
        }
        th,td {
            font-size: 11px;
            text-transform: uppercase;
            padding: 10px 10px 10px 10px;
        }
        th {
            color: #8e9293;
        }
        td {
            text-align: left;
        }
    }
}
//
//  Product Details
//  ---------------------------------------------

.product-details {
    background-color: #ebf1f8;

    .page-main {
        padding-top: 0;
    }
}

//
//  Features
//  ---------------------------------------------

.features {
    background-color: $primary__color;
    padding-bottom: ($indent__l * 3);

    @include max-screen($screen__m) {
        padding-bottom: ($indent__l + $indent__base);
    }
    &-title {
        color: #8da5be;
        font-weight: 500;
        font-size: 19px;
        text-transform: uppercase;
        text-align: center;
        margin: $indent__l 0 ($indent__l * 2);

        @include max-screen($screen__m) {
            margin: $indent__m 0 $indent__l 0;
        }
    }
    &-list {
        display: none;
        @extend .abs-reset-list;
        padding: 0 20%;

        @include max-screen($screen__l) {
            padding: 0 10%;
        }
        @include max-screen($screen__m) {
            padding: 0;
        }
        &.slick-initialized {
            display: block;
        }
        .row {
            @include min-screen($screen__xs) {
                display: flex;
                align-items: center;
            }
        }
        img {
            width: 100%;

            @include max-screen($screen__xs) {
                margin-bottom: $indent__s;
            }
        }
        h3 {
            color: #ffffff;
            font-size: 23px;
            margin-top: $indent__s;
        }
        p {
            color: #8da5be;
            font-weight: 500;
            font-size: 15px;
        }
        .slick-dots {
            position: absolute;
            bottom: -71px;
            left: 50%;
            transform: translateX(-50%);
            text-align: center;
            padding: 0;

            @include max-screen($screen__m) {
                bottom: -41px;
            }
            li {
                display: inline-block;
                margin: 0 7px;

                button {
                    display: block;
                    border: 0;
                    margin: 0;
                    padding: 0;
                    text-indent: -999em;
                    overflow: hidden;
                    width: 11px;
                    height: 11px;
                    border-radius: 50%;
                    background-color: #2b3f54;
                }
                &.slick-active {
                    button {
                        background-color: #8da5be;
                    }
                }
            }
        }
    }
    &-loading {
        color: #8da5be;
        text-align: center;
    }
}

//
//  Sharer
//  ---------------------------------------------

.sharer {
    @include lib-clearfix();
    margin-bottom: $indent__base;
    @include max-screen($screen__l) {
        margin-bottom: $indent__s;
    }
    a {
        @include lib-css(transition, color 0.2s, 1);
        float: left;
        margin-right: $indent__s;
        color: #112e42;
        font-size: 34px;
        &:hover {
            text-decoration: none;
            color: $secondary__color;
        }
        .fab,
        .fas {
            display: block;
        }
        span {
            display: none;
        }
    }
}

//
//  Category Top
//  ---------------------------------------------

.category-top,
.page-products .page-title-wrapper {
    position: relative;
    overflow: hidden;

    img {
        width: 100%;
        display: block;

        @include max-screen($screen__m) {
            width: auto;
            max-width: none;
            transform: translateX(-50%);
        }
    }
    .overlay {
        display: flex;
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
    }

    h1 {
        width: 100%;
        align-self: center;
        text-align: center;
        text-transform: uppercase;
        font-size: 22px;
        font-weight: 500;
        color: $color-white;
        margin: 0;
        position: relative;

        &:before {
            content: ' ';
            position: absolute;
            background: #3a3d3f;
            height: 1px;
            width: 100%;
            left: 0;
            top: 50%;
        }

        span{
            position: relative;
            background: #181c1e;
            padding: 0 35px;
        }
        @include max-screen($screen__l) {
            font-size: 30px;
        }
        @include max-screen($screen__m) {
            font-size: 24px;
        }
    }
    &.page-title-wrapper {
        h1 {
            color: #fff;
            font-size: 22px;
            width: 66.66667%;
            margin: 0 auto 40px;
            float: none;
            display: block;

            @include max-screen($screen__xl) {
                margin-bottom: $indent__l;
            }
            @include max-screen($screen__l) {
                margin-bottom: $indent__base;
            }
            @include max-screen($screen__m) {
                margin-bottom: $indent__m;
                font-size: 24px;
                width: 100%;
                text-align: left;

                span{
                    padding-left: 0px;
                }
            }
        }
    }
}


//
//  Category view
//  ---------------------------------------------

.prices-tier {
    .price-container {
        .price-including-tax {
            + .price-excluding-tax {

            }
        }

        .weee[data-label] {
            display: inline;

            .price {
                @include lib-font-size(11);
            }

            &:before {
                content: ' +' attr(data-label) ': ';
            }
        }
    }
}

.actual-price {
    font-weight: $font-weight__bold;
}

.category-view {

    @include make-row();

    .category-description {
        margin: 0 auto 50px;
        text-align: center;
        max-width: 750px;
        float:none !important;
        color: #8d8e94;
        padding: 0 20px;

        p {
            line-height: 1.75;

            @include max-screen($screen__m) {
                font-size: 13px;
            }
        }
    }
}

//
//  Product images general container
//  ---------------------------------------------

.product-image-container {
    display: block;
    max-width: 100%;
    width: auto !important;
}

.product-image-wrapper {
    display: block;
    height: 0;
    overflow: hidden;
    position: relative;
    z-index: 1;
}

.product-image-photo {
    bottom: 0;
    display: block;
    height: auto;
    left: 0;
    margin: auto;
    max-width: 100%;
    max-height: 100%;
    position: absolute;
    right: 0;
    top: 0;
}

//
//  Product view
//  ---------------------------------------------

.gallery-placeholder{
    background:white;
    margin-bottom: 20px;
}
.product.media {
    position: relative;
    width: 100%;
    margin-bottom:30px;

    .product.photo .photo.image {
        @extend .abs-adaptive-images-centered;
    }

    .placeholder .photo.container {
        max-width: 100%;
    }

    .notice {
        @include lib-css(color, $text__color__muted);
        @include lib-font-size($font-size__s);
        margin: $indent__s 0;
    }

    .product.thumbs {
        margin: $indent__base 0 $indent__l;
    }

    .items.thumbs {
        @include lib-list-inline();

        .active {
            display: block;
            line-height: 1;
        }
    }
}

//custom-media
#media-main-wrapper{
    margin-bottom: 30px;

    .fotorama{

        overflow: visible;

        .fotorama__arr--next{
            right: 0 !important;
        }
        .fotorama__stage {
            position: relative;
            max-width: 100%;
            overflow: hidden;
            width: 100% !important;
            left: 0 !important;
        }

        .fotorama__stage__shaft{
            margin-left: 0 !important;
            width: 100% !important;
            max-width: 100% !important;
        }

        .fotorama__thumb-border{
            display: none !important;
        }
        .fotorama__nav-wrap{
            position: absolute;
            left: -100000px;
            width: 0;
            height: 0 !important;

            .fotorama__nav--thumbs {
                display: block;
                height: 200px !important;
                margin: 0;
                width: 100% !important;
            }
            .fotorama__nav__shaft{
                width: 100% !important;
                height: 200px !important;
                background: transparent;

                .fotorama__nav__frame{
                    width: 46% !important;
                    float: left !important;
                    display: block !important;
                    height: auto !important;
                    margin: 0 1% 3%;

                    .fotorama__thumb{
                        position: relative !important;
                        top: 0 !important;
                        width: 100%;
                        height: auto;
                        transform: none !important;
                        border: 3px solid #303335;

                        .fotorama__img{
                            position: relative !important;
                            width: 100% !important;
                        }
                    }
                }
            }
            .fotorama__active{
                .fotorama__thumb{
                    border: 3px solid #d4ab3c !important;
                }
            }
        }
    }

    #thumbnails{
        margin:  -5px -5px 10px;
        left: -9999px;

        &.slick-initialized {
            position: relative;
            left: 0;

            .slick-slide{
                vertical-align: middle;
                width: 100% !important;
            }
        }
        span{
            width: 50%;
            box-sizing: border-box;
            float: left;
            padding: 5px;

            img{
                width: 100%;
                vertical-align: middle;
            }
        }
        display: block;
        width: auto;
        position: absolute;
        background-color: #303335 !important;
        opacity: 0.5;

        .slick-prev,
        .slick-next{
            display: block;
            width: 100%;
            position: absolute;
            background-color: #303335 !important;
            opacity: 0.5;
            left: 5px;
            -webkit-transform: translate(0,0%) !important;
            -moz-transform: translate(0, 0%) !important;
            -ms-transform: translate(0,0%) !important;
            transform: translate(0,0%) !important;
        }
        .slick-prev{
            top: 5px;
            bottom: auto;
            background-image: url(../images/arrow-up.png) !important;
            position: absolute;
            margin: 0;
            -webkit-transform: rotate(0deg) translate(0,50%);
            -moz-transform: rotate(0deg) translate(0,50%);
            -ms-transform: rotate(0deg) translate(0,50%);
            transform: rotate(0deg) translate(0,50%);
        }
        .slick-next{
            top: auto;
            bottom: 5px;
            background-image: url("../images/arrow-down.png") !important;
        }
    }

}


.product.info.detailed {
    clear: both;
    margin-bottom: 30px;
    table {
        margin-bottom: $indent__base;
        tr {
            @include max-screen($screen__l) {
                border-bottom: 2px solid $secondary__color;
            }
            &:first-child {
                td, th {
                    border-bottom: 2px solid $secondary__color;
                    @include max-screen($screen__l) {
                        border: 0;
                    }
                }
            }
            &:last-child {
                td, th {
                    border-bottom: 0;
                }
            }
            td, th {
                border-bottom: 1px solid $secondary__color;
                @include max-screen($screen__l) {
                    display: block;
                    border: 0;
                }
            }
            th {
                border-right: 2px solid $secondary__color;
                @include max-screen($screen__l) {
                    border: 0;
                }
            }
        }
    }
}

.product.data.items{
    background:#344d42;

    & > .item.title {
        & > .switch:hover,
        & > .switch:active {
            color: #ffffff !important;
        }
    }
}
.product-info-main {
    width: 100%;

    @include min-screen($screen__m) {
        box-sizing: border-box;

    }
    .page-title-wrapper {
        border-bottom: 1px solid #505154;
        @include lib-clearfix();

        .page-title {
            font-size: 25px;
            font-weight: 300;
            margin: 0;
            width: 65%;
            float: left;
            padding-bottom: 15px;
        }

        .product-info-price{
            padding-left: 15px;
            padding-right: 15px;
            float: right;
            width: 33.33333%;
            box-sizing: border-box;

            @include max-screen($screen__l) {
                line-height: 20px;
                margin-bottom: 20px;

                .price{
                    display: block;
                }
            }

        }
    }

    & > img {
        margin-bottom: $indent__xl;
        margin-top: $indent__s;
    }

    .product-info-stock-sku {
        margin: $indent__xs 0;

        .stock.available,
        .stock.unavailable {
            display: none;
        }
        .product.attribute.sku {
            color: #94acc6;
            display: none;
            .type {
                display: none;
            }
        }
    }

    .stock {
        &.available,
        &.unavailable {
            display: inline-block;
            font-weight: 500;
            vertical-align: top;
            margin: $indent__base 0;

            .fas {
                margin-right: 4px;
            }
        }
        &.available {
            color: #ffffff;
            font-size: 11px;
            margin-bottom: 7px;
            text-transform: uppercase;
        }
        &.unavailable {
            color: #f61717;
        }
    }

    .product {
        &.attribute {
            &.sku {
                display: inline-block;
                vertical-align: top;
                @include lib-css(color, $text__color__muted);

                > .value {
                    display: inline-block;
                    vertical-align: top;
                    word-break: break-all;
                }

                .type {
                    margin-right: $indent__xs;
                }
            }

            &.overview {
                margin: $indent__base 0;
            }
        }

        &.alert {
            margin: $indent__s 0;
        }
    }

    .product-reviews-summary .reviews-actions {
        @include lib-font-size(13);
        font-weight: 300;
    }
}

.product-options-wrapper {
    .fieldset-product-options-inner {
        .legend {
            @include lib-css(font-weight, $font-weight__bold);
            @include lib-css(margin, 0 0 $indent__xs);
            @include lib-font-size(14px);
            border: none;
            display: inline-block;
            float: none;
            padding: 0;
        }

        //  Date & Time custom option (Affect Time that goes only after Date)
        input.datetime-picker {
            ~ select.datetime-picker {
                margin-top: $indent__s;
            }
        }

        &.required,
        &._required {
            .legend {
                &:after {
                    content: '*';
                    @include lib-typography(
                        $_font-size   : $form-field-label-asterisk__font-size,
                        $_color       : $form-field-label-asterisk__color,
                        $_font-family : $form-field-label-asterisk__font-family,
                        $_font-weight : $form-field-label-asterisk__font-weight,
                        $_line-height : $form-field-label-asterisk__line-height,
                        $_font-style  : $form-field-label-asterisk__font-style
                    );
                    @include lib-css(margin, $form-field-label-asterisk__margin);
                }
            }
        }
    }

    .field {
        .note {
            display: block;
        }

        .price-notice {
            @extend .abs-adjustment-incl-excl-tax;
        }
    }
}

.product-info-main,
.product-options-bottom {

    .price-box {
        display: block;
        font-size: 25px;
        font-weight: 500;
        color: #fff;

        @include max-screen($screen__l) {
            margin-top: $indent__s;
        }
        .price-excluding-tax{
            margin-right: 10px;
        }
        .price-including-tax {
            @include lib-font-size(16);
            font-weight: normal;
            .price{
                display: inline-block;
                padding-right: 5px;
            }
            &:after {
                content: attr(data-label);
                font-size: 75%;
            }
        }
        .normal-price {
            .price-label {
                display: none !important;
            }
        }
        .old-price {
            @include lib-clearfix();
            display: block;
            @include lib-font-size(16);
            font-weight: normal;
            margin-top: $indent__xs;

            .price-label {
                display: none;
            }
            .price {
                text-decoration: line-through;
            }
            .price-excluding-tax {
                &:before {
                    content: 'Was ';
                }
                &:after {
                    content: ' ' attr(data-label);
                }
            }
            .price-including-tax {
                &:before {
                    content: 'Was ';
                }
                &:after {
                    content: ' ' attr(data-label);
                }
            }
        }
        .special-price {
            display: block;
            color: #f61717;

            .price-label {
                display: none;
            }
        }
    }

    .box-tocart {
        margin: $indent__base 0;
        @include max-screen($screen__l) {
            margin: $indent__s 0;
        }
        .label {
            font-size: 12px;
            font-weight: 400;
        }

        .qty {
            @include lib-clearfix();

            .label {
                display: none;
            }
            #qty {
                float: left;
                width: 60px;
                text-align: center;
                margin: 0 $indent__xs;
            }
            &-minus,
            &-plus {
                float: left;
                line-height: 40px;
                width: 50px;
                text-align: center;
                background-color: #dee5ee;
                color: $primary__color;
            }
        }

        .actions {
            margin-top: $indent__base;
            text-align: center;

            .action.tocart {
                font-size: 16px;
                font-weight: 500;
            }
        }

        .action.tocart {
            @extend .abs-button-l;
        }
    }

    .product-addto-links {
        margin: $indent__base 0;
    }

    .action.tocompare {
        @extend .abs-action-addto-product;
        vertical-align: top;
    }
}

.prices-tier {
    @extend .abs-reset-list;
    @include lib-css(background, $sidebar__background-color);
    margin-top: $indent__s;

    .price-container {
        display: inline-block;
    }

    .price-including-tax,
    .price-excluding-tax,
    .weee {
        display: inline-block;
        color: $tertiary__color;

        .price {
            @include lib-font-size(14);
        }
    }
    .price-including-tax {
        &:after {
            content: "inc VAT";
            margin-left: $indent__xs;
        }
    }
}

.ui-dialog-titlebar-close {
    @include lib-button-as-link();
}

.block.related {
    .action.select {
        margin: 0 $indent__xs;
    }
}

//
//  Sidebar product view
//  ---------------------------------------------

.sidebar {
    .product-items {
        .product-item {
            margin-bottom: $indent__base;
            position: relative;
        }

        .product-item-info {
            position: relative;
            width: auto;

            .product-item-photo {
                left: 0;
                position: absolute;
                top: 0;
            }
        }

        .product-item-name {
            margin-top: 0;
        }

        .product-item-details {
            margin: 0 0 0 85px;
        }

        .product-item-actions {
            display: block;
            margin-top: $indent__s;
        }

        .price-box {
            display: block;
            margin: 7px 0;
        }

        .text {
            margin-right: 8px;
        }

        .counter {
            @include lib-css(color, $primary__color__lighter);
            @include lib-font-size(12);
            white-space: nowrap;
        }

        .minilist {
            .price {
                display: inline;
                padding: 0;
            }

            .weee:before {
                display: inline-block;
            }
        }
    }

    .action {
        &.delete {
            @extend .abs-remove-button-for-blocks;
            position: absolute;
            right: 0;
            top: 0;
        }
    }

    .subtitle {
        @extend .abs-no-display;
    }

    //
    //  Product images only
    //  ---------------------------------------------

    .product-items-images {
        @extend .abs-add-clearfix;
        margin-left: -$indent__xs;

        .product-item {
            @extend .abs-add-box-sizing;
            float: left;
            padding-left: $indent__xs;
        }
    }

    //
    //  Product names only
    //  ---------------------------------------------

    .product-items-names {
        .product-item {
            margin-bottom: $indent__s;
        }

        .product-item-name {
            margin: 0;
        }
    }
}

//
//  Mobile
//  _____________________________________________

@include max-screen($screen__m) {
    .catalog-product-view {
        .column.main {
            @include lib-vendor-prefix-display(flex);
            @include lib-vendor-prefix-flex-direction(column);
        }

        .product.media {
            @include lib-vendor-prefix-order(-1);
            margin-bottom: $indent__base;
        }
    }

    .product-info-main .box-tocart {
        .actions {
            .action.tocart {
                @extend .abs-button-responsive-smaller;
            }
        }
    }

    .block.related {
        .action.select {
            display: block;
            margin: $indent__xs 0;
        }
    }

    .compare,
    .product-addto-links .action.tocompare,
    .product-item-actions .actions-secondary > .action.tocompare,
    [class*='block-compare'] {
        display: none;
    }
}

.product-add-form {
    .fieldset {
        & > .field {
            margin: 0 0 $indent__base;
            .label {
                font-weight: normal;
                font-size: 12px;
            }
        }
    }
}

.product-delivery-message {
    margin-top: $indent__base;
}


//
//  Desktop
//  _____________________________________________

@include min-screen($screen__m) {
    .product-info-main,
    .product-options-bottom {
        .box-tocart {

            .field.qty {

            }

            .actions {
                text-align: center;
                vertical-align: bottom;
            }
        }
    }

    .sidebar {
        .product-items {
            .product-item-info {
                .product-item-photo {
                    float: left;
                    left: auto;
                    margin: 0 $indent__s $indent__s 0;
                    position: relative;
                    top: auto;
                }
            }

            .product-item-details {
                margin: 0;
            }

            .product-item-actions {
                clear: left;
            }
        }
    }

    .product-add-form {
        @extend .abs-revert-field-type-desktop;
    }
}

//
//  Desktop large
//  _____________________________________________

@include min-screen($screen__xl) {
    .sidebar {
        .product-items {
            .product-item-info {
                .product-item-photo {
                    float: none;
                    left: 0;
                    margin: 0;
                    position: absolute;
                    top: 0;
                }
            }

            .product-item-details {
                margin-left: 85px;
            }
        }
    }
}

//
//  Category page layout
//  ---------------------------------------------

@include min-screen($screen__m) {
    .product-info-main {
        float: right;
    }

    .product.media {
        float: left;
    }


    .page-layout-1column {
        .product-info-main {
            width: 50%;
        }
        .product.media {
            width: 50%;
        }
    }
}

@include min-screen($screen__l) {
    .page-layout-1column {
        .product-info-main {
            width: 35%;
        }
        .product.media {
            width: 65%;
        }

    }
}

@include min-screen($screen__xl) {
    .page-layout-1column {
        .product-info-main {
            width: 35%;
        }
        .product.media {
            width: 65%;
        }

    }
}

//
//  Compare Products Page
//  ---------------------------------------------

body.catalog-product-compare-index {
    .action.print {
        float: right;
        margin: 15px 0;
    }
}

.table-wrapper.comparison {
    clear: both;
    max-width: 100%;
    overflow-x: auto;
}

.table-comparison {
    table-layout: fixed;

    .cell.label.remove,
    .cell.label.product {
        span {
            @extend .abs-visually-hidden;
        }
    }

    .cell.label,
    td:last-child {
        border-right: $table__border-width $table__border-style $table__border-color;
    }

    .cell {
        padding: 15px;
        width: 140px;

        .attribute.value {
            overflow: hidden;
            width: 100%;
        }

        &.product.info,
        &.product.label {
            border-bottom: $table__border-width $table__border-style $table__border-color;
        }

        &.label {
            .attribute.label {
                display: block;
                width: 100%;
                word-wrap: break-word;
            }
        }

        &.attribute {
            @include lib-font-size(13);
            img {
                height: auto;
                max-width: 100%;
            }
        }
    }

    .product-item-photo {
        display: block;
        margin: 0 auto 15px;
    }

    .product-image-photo {
        margin-left: 0;
    }

    .product-item-actions,
    .price-box,
    .product.rating,
    .product-item-name {
        display: block;
        margin: 15px 0;
    }

    .product-addto-links {
        margin-top: 15px;

        .action.split,
        .action.toggle {
            @include lib-button-s();
        }

        .action.toggle {
            padding: 0;
        }
    }

    .cell.remove {
        padding-bottom: 0;
        padding-top: 0;
        text-align: right;

        .action.delete {
            @extend .abs-remove-button-for-blocks;
        }
    }

    .product-item-actions {
        > .actions-primary {
            + .actions-secondary {
                margin-top: $indent__s;
            }
        }
    }

    .action {
        &.tocart {
            white-space: nowrap;
        }
    }
}

.comparison.headings {
    @include lib-css(background, $page__background-color);
    left: 0;
    position: absolute;
    top: 0;
    width: auto;
    z-index: 2;
}

.block-compare {
    .block-title {
        @extend .abs-block-title;
    }

    .product-item .product-item-name {
        margin-left: 22px;
    }

    .action {
        &.delete {
            @extend .abs-remove-button-for-blocks;
            left: -6px;
            position: absolute;
            top: 0;
        }

        &.compare {
            @extend .abs-revert-secondary-color;
        }
    }

    .counter {
        @extend .abs-block-items-counter;
    }

    .actions-toolbar {
        margin: 17px 0 0;
    }
}
