@import 'gallery360-theme';

#gallery360 {
    display: none;
    position: absolute;
    top: 0;
    right: 0;
    z-index: 20;
    cursor: pointer;
    -webkit-transition: transform 150ms ease-out;
    -moz-transition: transform 150ms ease-out;
    -o-transition: transform 150ms ease-out;
    -ms-transition: transform 150ms ease-out;
    transition: transform 150ms ease-out;
    opacity: 0.5;

    width: 80px;
    height: 36px;
    background-image: url('../images/360.png');
    background-size: cover;

    &.active {
        background-image: url('../Magento_Catalog/img/video.png');
        background-position: center center;
        background-size: auto;
        background-repeat: no-repeat;
        height: 36px;
        width: 80px;
    }
    &.has-video {
        display: block;

        ~ #topContainer {
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
        }
        ~ .gallery-placeholder {
            &.hidden {
                visibility: hidden;
                display: block;
            }
        }
    }
    &:hover {
        -webkit-transform: scale(1.1, 1.1);
        -moz-transform: scale(1.1, 1.1);
        -o-transform: scale(1.1, 1.1);
        -ms-transform: scale(1.1, 1.1);
        transform: scale(1.1, 1.1);
        opacity: 1;
    }
}
#topContainer {
    -webkit-touch-callout: none;
    -webkit-user-select: none;
    -khtml-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;

    &.hidden {
        visibility: hidden;
    }
    #imageContainer {
        position: absolute;
        top:0;
        left: 15px;
        width: 100%;
        height: 100%;
        z-index: 1;
        overflow: hidden;
    }
    .image {
        -webkit-transition: opacity 0.2s ease-in-out;
        -moz-transition: opacity 0.2s ease-in-out;
        -ms-transition: opacity 0.2s ease-in-out;
        -o-transition: opacity 0.2s ease-in-out;
        transition: opacity 0.2s ease-in-out;
    }
    .imageInvisible {
        position: absolute;
        visibility: hidden;
    }
    .imageOpaque {
        position: absolute;
        opacity: 0.5;
        -webkit-transition: opacity 0.2s ease-in-out;
        -moz-transition: opacity 0.2s ease-in-out;
        -ms-transition: opacity 0.2s ease-in-out;
        -o-transition: opacity 0.2s ease-in-out;
        transition: opacity 0.2s ease-in-out;
    }
    .imageVisible {
        position: absolute;
        opacity: 0.8;
        -webkit-transition: opacity 0.2s ease-in-out;
        -moz-transition: opacity 0.2s ease-in-out;
        -ms-transition: opacity 0.2s ease-in-out;
        -o-transition: opacity 0.2s ease-in-out;
        transition: opacity 0.2s ease-in-out;
    }
    #hotspotcanvas {
        position: absolute;
        left: 0;
        right: 0;
        margin-left: auto;
        margin-right: auto;
        /*-ms-transform: translateX(-50%);
        -webkit-transform: translateX(-50%);
        transform: translateX(-50%);*/
        z-index: 8;
        cursor: ew-resize;
        width: 100%;
        height: 100%;
    }
    #zoomContainer {
        position: relative;
        z-index: 9;
        width: 100%;
        height: 100%;
        overflow: hidden;
        display: none;
    }
    #draggableContainer {
        position: relative;
        width: 100%;
        height: 100%;
        cursor: move;
    }
    #controllerContainer {
        position: absolute;
        display: inline-block;
        bottom: 0;
        left: 0;
        z-index: 10;
        padding: 8px;
    }
    #logoContainer {
        position: absolute;
        display: inline-block;
        top: 0;
        left: 0;
        z-index: 11;

        img {
            position: absolute;
            top: 0px;
            left: 0px;
            width: auto;
            height: auto;
            -ms-transform: translateY(0%);
            -webkit-transform: translateY(0%);
            transform: translateY(0%);
        }
    }
    #deepzoom {
        display: inline-block;
        color: #1390ff;
    }
    #progressLoader {
        position: absolute;
        top: 0px;
        left: 0px;
        width: 100%;
        height: 100%;

        z-index: 10;

        background: rgb(255,255,255); /* Old browsers */
        background: -moz-linear-gradient(top,  rgba(255,255,255,1) 0%, rgba(229,229,229,1) 100%); /* FF3.6-15 */
        background: -webkit-linear-gradient(top,  rgba(255,255,255,1) 0%,rgba(229,229,229,1) 100%); /* Chrome10-25,Safari5.1-6 */
        background: linear-gradient(to bottom,  rgba(255,255,255,1) 0%,rgba(229,229,229,1) 100%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
        filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#ffffff', endColorstr='#e5e5e5',GradientType=0 ); /* IE6-9 */
        -webkit-box-shadow: inset 0px 0px 8px #000000;
        -moz-box-shadow: inset 0px 0px 8px #000000;
        -o-box-shadow: inset 0px 0px 8px #000000;
        -ms-box-shadow: inset 0px 0px 8px #000000;
        box-shadow: inset 0px 0px 8px #000000;
    }
    #loaderContainer {
        position: absolute;
        display: inline-block;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        z-index: 10;
        background: #feffff; /* Old browsers */
        background: -moz-linear-gradient(top,  #feffff 0%, #ddf1f9 35%, #a0d8ef 100%); /* FF3.6-15 */
        background: -webkit-linear-gradient(top,  #feffff 0%,#ddf1f9 35%,#a0d8ef 100%); /* Chrome10-25,Safari5.1-6 */
        background: linear-gradient(to bottom,  #feffff 0%,#ddf1f9 35%,#a0d8ef 100%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
        filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#feffff', endColorstr='#a0d8ef',GradientType=0 ); /* IE6-9 */
        -webkit-box-shadow: 0px 0px 8px #000000;
        -moz-box-shadow: 0px 0px 8px #000000;
        -o-box-shadow: 0px 0px 8px #000000;
        -ms-box-shadow: 0px 0px 8px #000000;
        box-shadow: 0px 0px 8px #000000;
    }
    #progressLabel {
        margin: 8px 0px 0px 0px;
    }
    #progressElement {
        margin: 0px 8px 8px 8px;
    }
    #inverted-contain {
        position: absolute;
        left: 0;
        top: 0;
        width: 100%;
        height: 100%;
        margin: 0px;
        padding: 0px;

        .parent {
            width: 100%;
            height: 100%;
            margin: 0px;
            padding: 0px;

            .panzoom {
                width: 100%;
                height: 100%;
                margin: 0px;
                padding: 0px;
            }
        }
    }
    .zoomimg {
        display: block;
        position: absolute;
        width: auto;
        height: 100%;
        margin: 0px;
        padding: 0px;
        top: 0px;
        left: 50%;
        -webkit-transform: translateX(-50%);
        -ms-transform: translateX(-50%);
        transform: translateX(-50%);
    }
    #videoElement {
        height: 100%;
    }
}
