.product-options-bottom,
.block-bundle-summary {
    .action.towishlist {
        @extend .abs-action-addto-product;
    }
}

#wishlist-view-form .actions-toolbar {
    margin: 0;
    .action {
        width: auto;
    }
}

.product-info-main,
.product-items,
.products.list.items,
.table-comparison {
    .action {
        &.towishlist {
            text-transform: none;
            color: $primary__color;
            font-size: $font-size__s;
            &:before {
                @extend .fas;
                @include fa-icon;
                content: fa-content($fa-var-heart);
                font-weight: 900;
                vertical-align: -1px;
                margin-right: $indent__xs;
            }
            &:hover {
                text-decoration: none;
                color: $secondary__color;
            }
        }
    }
}

.account .table-wrapper .data.table.wishlist {
    @include lib-table-bordered(
        $_table_type: horizontal
    );

    thead > tr > th {
        border-bottom: 0;
    }

    tbody > tr:last-child > td {
        border-bottom: 1px solid $table__border-color;
    }

    .product.name {
        display: inline-block;
        margin-bottom: $indent__s;
    }

    .box-tocart {
        margin: $indent__s 0;

        .qty {
            @extend .abs-input-qty;
            vertical-align: middle;
        }
    }

    .col {
        &.item {
            width: 50%;
        }

        &.photo {
            max-width: 150px;
        }

        &.selector {
            max-width: 15px;
        }
    }

    textarea {
        margin: $indent__s 0;
    }

    .input-text.qty {
        margin-bottom: $indent__s;
    }

    .action.primary {
        vertical-align: top;
    }

    .price {
        font-weight: $font-weight__bold;
    }
}

.block-wishlist {
    .block-title {
        @extend .abs-block-title;
    }

    .counter {
        @extend .abs-block-items-counter;
    }

    .product-item-name {
        margin-right: $indent__m;
    }
}

.products-grid.wishlist {
    margin: 0;
    .product {
        &-item {
            @include make-xxs-column(6);
            @include make-s-column(4);
            @include make-l-column(3);
            &-photo {
                display: block;
                margin-bottom: $indent__s;
            }

            &-name {
                margin-top: 0;
            }

            .price-box {
                margin: 0;
            }

            .comment-box {
                .label {
                    @extend .abs-visually-hidden;
                }
            }

            .field.qty {
                margin-bottom: $indent__s;
            }

            &-comment {
                display: block;
                height: 42px;
                margin: $indent__s 0;
            }

            &-actions {
                margin-bottom: $indent__s;
                > * {
                    margin-right: 15px;

                    &:last-child {
                        margin-right: 0;
                    }
                }
                .actions-primary {
                    display: block;
                }
            }

            .box-tocart {
                input.qty {
                    @extend .abs-input-qty;
                    height: 32px;
                }
            }
        }
    }
}
