//
//  Variables
//  _____________________________________________

$checkout-field-validation__border-color: $form-element-validation__border-error;

.opc-wrapper {
    .fieldset {
        > .field {
            margin-bottom: $indent__s;
            > .label {
                font-weight: $font-weight__regular;
                width: 100%;
                text-align: left;
                font-size: 12px;
                padding: $indent__s 0 $indent__xs;
            }

            .control {
                &._with-tooltip {
                    @extend .abs-field-tooltip;
                }
            }

            &.choice {
                .field-tooltip {
                    display: inline-block;
                    margin-left: $indent__s;
                    position: relative;
                    top: -3px;
                }

                .field-tooltip-action {
                    @include lib-css(line-height, $checkout-tooltip-icon__font-size);
                }
            }

            &._error {
                .control {
                    input,
                    select,
                    textarea {
                        @include lib-css(border-color, $checkout-field-validation__border-color);
                    }
                }
            }
        }
    }
}

//
//  Desktop
//  _____________________________________________

@include min-screen($screen__m) {
    .opc-wrapper {
        .fieldset {
            > .field {
                > .control {
                    width: 100%;
                }
                &.required,
                &._required {
                    position: relative;
                    &:after {
                        margin-left: $indent__xs;
                        position: absolute;
                        top: 4px;
                    }
                }
            }
        }
    }
}
