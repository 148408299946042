//
//  Layout variables
//  _____________________________________________

$layout__max-width                                              : 1390px;

//
//  Color variables
//  _____________________________________________

$primary__color                                                 : #354d42; // dark blue (text, headings)
$secondary__color                                               : #d4ab3c; // bright blue (buttons)
$tertiary__color                                                : #181c1e; // pale blue (price)
$quarteriary__color                                             : #d0dae6; // light blue (mottos)
$special__color                                                 : #ec3d41;
$border__color                                                  : #e7ecf2;
$color-blue2                                                      : #d4ab3c !default;
$active__color                                                  : $secondary__color;

//
//  Typography variables
//  _____________________________________________

//  Font families
$font-family-name__base                                         : 'Avenir-Book';
$heading__font-family__base                                     : 'Avenir-Roman';
//  Colors
$text__color                                                    : #fff;

//  Indent
$indent__m                                                      : 15px;

//
//  Headings
//  ---------------------------------------------

$heading__font-weight__base                                     : 500;
$heading__margin-top__base                                      : 20px;
$h2__margin-top                                                 : $heading__margin-top__base;
$h3__margin-top                                                 : $heading__margin-top__base;

//
//  Focus
//  ---------------------------------------------

$focus__box-shadow                                              : none;

//
//  Breadcrumbs variables
//  _____________________________________________

$breadcrumbs__container-margin                                  : 21px 0 20px;
$breadcrumbs__font-size                                         : 11px;

$breadcrumbs-icon__font-color                                   : #b8c1d1;

$breadcrumbs-current__color                                     : $primary__color;
$breadcrumbs-current__font-weight                               : 300;

//
//  Tabs variables
//  _____________________________________________

$tab-control__color                                             : #fff;
$tab-control__font-weight                                       : 500;
$tab-control__margin-right                                      : 0;
$tab-control__padding-top                                       : 18px;
$tab-control__padding-right                                     : 65px;
$tab-control__padding-bottom                                    : 18px;
$tab-control__padding-left                                      : 65px;
$tab-control__border-color                                      : #f8f8f8;
$tab-control__border-width                                      : 0;

$tab-control__background-color                                  : transparent;
$tab-control__hover__background-color                           : #1f372c;
$tab-control__active__background-color                          : #1f372c;

$tab-content__background-color                                  : #ededed;
$tab-content__border                                            : 0;
$tab-content__padding-top                                       : 20px;
$tab-content__padding-bottom                                    : 20px;

$accordion-control__height                                      : auto;
$accordion-control__line-height                                 : 1.428571429;
$accordion-control__font-size                                   : 14px;
$accordion-control__margin-bottom                               : 0;
$accordion-control__padding-top                                 : 18px;
$accordion-control__padding-right                               : 18px;
$accordion-control__padding-bottom                              : 18px;
$accordion-control__padding-left                                : 18px;
$accordion-control__hover__background-color                     : #1f372c;
$accordion-control__hover__color                                : $primary__color;
$accordion-control__active__background-color                    : #1f372c;
$accordion-control__active__color                               : $primary__color;

//
//  Table variables
//  _____________________________________________

$table-cell__padding-horizontal                                 : 20px;

//
//  Navigation variables
//  _____________________________________________

$navigation-level0-item__color                                  : $primary__color;
$navigation-level0-item__active__border-color                   : $secondary__color;
$navigation-level0-item__font-weight                            : 500;

$navigation-desktop__font-weight                                : 500;
$navigation-desktop-level0-item__margin                         : 0;
$navigation-desktop-level0-item__padding                        : 2px 0 0;
$navigation-desktop-level0-item__line-height                    : 19px;
$navigation-desktop-level0-item__color                          : $primary__color;
$navigation-desktop-level0-item__hover__color                   : $primary__color;
$navigation-desktop-level0-item__hover__border-width            : 4;
$navigation-desktop-level0-item__active__color                  : $primary__color;
$navigation-desktop-level0-item__active__border-width           : 4;

$submenu-desktop__font-weight                                   : 500;
$submenu-desktop-item__active__border-color                     : $secondary__color;

//
//  Messages variables
//  _____________________________________________

$message__margin                                                : 0 0 20px;

//
//  Links
//  ---------------------------------------------

$link__color                                                    : $secondary__color;

//
//  Buttons
//  ---------------------------------------------

$button__disabled__opacity                                      : 0.1;

//  Font style
$button__font-size                                              : 12px;
$button__padding                                                : 11px 15px;
$button__font-weight                                            : 500;

//
//  Form variables
//  _____________________________________________

$form-element-input__height                                     : 40px;
$form-element-input__border-color                               : #b3becb;
$form-element-input__font-size                                  : 13px;
$form-element-input__font-weight                                : 500;
$form-element-input__color                                      : $primary__color;

//
//  Fieldset
//  ---------------------------------------------

$form-fieldset-legend__margin                                  : false;

//
//  Field
//  ---------------------------------------------

//  Form field label
$form-field-label__font-size                                    : 13px;
$form-field-label__font-weight                                  : 300;
$form-field-type-label-inline__padding                          : 11px 15px 11px 0;

//  Form field label asterisk
$form-field-label-asterisk__margin                              : 0;

//
//  Header
//  _____________________________________________

$header-icons-color                                             : $primary__color;
$header-icons-color-hover                                       : $secondary__color;

//
//  Footer
//  _____________________________________________

$footer-text__color                                             : #fff;
$footer-background-dark__color                                  : #112e42;
$footer-background-light__color                                 : #505154;



//
//  Other variables
//  _____________________________________________

$checkout-shipping-item__active__border-color                   : $secondary__color;
$account-nav-current-border-color                               : $secondary__color;


// Ratings
//
//----------------------------------------------
$rating-icon__color          : #303335 !default;
$rating-icon__active__color  : #d4ab3c !default;


$sidebar__background-color                                    : #505154 !default;
