//
//  Gift Card Account
//  ____________________________________________

.amcard-codes-list,
.amcard-field-container {
    .amcardacc-template-container & {
        max-width: 360px;
    }
    &.-cart {
        max-width: 600px;
    }
    &.-paypal {
        margin: 20px 0;
    }
    .amcard-check,
    .amcard-delete {
        position: relative;
        align-self: flex-start;
        padding: 0;
        border: none;
        background: none;
        box-shadow: none;
        color: #1787e0;
        cursor: pointer;

        &:hover {
            text-decoration: underline;
        }
    }
    .amcard-check {
        margin-top: 10px;
    }
}
.amcard-field-block {
    &.-code {
        position: relative;
        margin-top: 10px;
    }
    .amcard-field {
        height: 41px;
    }
    .amcard-button {
        position: absolute;
        right: 0;
        top: 0;
        @extend .action.primary;
    }
}
.amcard-card-info {
    display: flex;
    margin-bottom: 10px;
    padding: 10px;
    background: #e8f6ff;

    .amcard-field:not(:last-child) {
        margin-right: 20px;
    }
}
.amcardacc-messages-container {
    display: none;

    &.-visible {
        display: block;
    }
}
.amcard-form-container .amcard-field.-validate {
    position: absolute;
    width: 0;
    opacity: 0;
}
