/*******************************************************************************************\
* Copyright 2002-2014 (C) Digital Multi-Media Design (DMMD), LLC (http://www.dmmd.net)      *
* This file is part of DMMD's Software Library.                                             *
* The software is distributed in the hope that it will be useful, but WITHOUT ANY WARRANTY; *
* without even the implied warranty of MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE. *
* DMMD can customize or expand this code for your own application.                          *
* Please contact us at contact@dmmd.net or via phone, at 7 0 3 - 4 3 9 - 0 0 6 7 (USA)      *
* or visit us at our website (http://dmmd.net).                                             *
\*******************************************************************************************/

#play, #prev, #next, #zoomin, #fullscreen {
    font-size: 20px;
    display: inline-block;
    text-align: center;
    line-height: 32px;
    width: 32px;
    height: 32px;
    -webkit-transition: all 150ms ease-out;
    -moz-transition: all 150ms ease-out;
    -o-transition: all 150ms ease-out;
    -ms-transition: all 150ms ease-out;
    transition: all 150ms ease-out;

    &:before {
        @include fa-icon;
        vertical-align: 0;
    }
    &:hover {
        -webkit-transform: scale(1.2, 1.2);
        -moz-transform: scale(1.2, 1.2);
        -o-transform: scale(1.2, 1.2);
        -ms-transform: scale(1.2, 1.2);
        transform: scale(1.2, 1.2);
    }
    &:active {
        opacity: 0.5;
    }
}
.play {
    &:before {
        @extend .far;
        content: fa-content($fa-var-play-circle);
    }
}
.pause {
    &:before {
        @extend .far;
        content: fa-content($fa-var-pause-circle);
    }
}
#prev {
    &:before {
        @extend .far;
        content: fa-content($fa-var-arrow-alt-circle-left);
    }
}
#next {
    &:before {
        @extend .far;
        content: fa-content($fa-var-arrow-alt-circle-right);
    }
}
.zoomin {
    &:before {
        @extend .fa;
        content: fa-content($fa-var-search-plus);
    }
}
.zoomout {
    &:before {
        @extend .fa;
        content: fa-content($fa-var-search-minus);
    }
}
#fullscreen {
    &:before {
        @extend .fa;
        content: fa-content($fa-var-arrows-alt);
    }
}
#deepzoom {
    display: inline-block;
    color: #1390ff;
}
