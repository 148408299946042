// /**
//  * Copyright © Studioworx. All rights reserved.
//  */

.block-product-link,
.block-category-link {
    &.widget {
        display: block;
        margin-bottom: $indent__base;
    }
}

.block-product-link-inline {
    &.widget {
        margin: 0;
    }
}

.products-grid {
    .widget-product-grid {
        padding: 0 $indent__xl;
        margin: 0;
        .homepage-featured & {
            display: none;
        }
        @include max-screen($screen__l) {
            padding: 0;
        }
    }
}
