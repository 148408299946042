//
//  Toolbar
//  _____________________________________________

$toolbar-mode-icon-font-size: 24px !default;
$toolbar-element-background : $panel__background-color !default;

.page-products {
    .columns {
        position: relative;
        z-index: 1;
    }
}

.toolbar {
    line-height: 40px;
    text-align: center;
    background: #303335;
    padding: 10px 25px;
    height: 35px;
}

#toolbar-bottom{
    padding: 20px 0 70px;
    
    .toolbar{
        padding: 0;
        background: transparent;

        .toolbar-amount,
        .sorter,
        .limiter{
            display: none;
        }

        .pages {
            display: block;
            float: right;
        }
    }
}

//
//  Toolbar Amount
//  ---------------------------------------------

.toolbar-amount {
    float: left;
    font-weight: 500;
    margin: 0;
    line-height: 36px;

    @include max-screen($screen__xl) {
        margin: 0 $indent__base $indent__s 0;
    }
    @include max-screen($screen__l) {
        margin: 0 $indent__s $indent__s 0;
    }
    @include max-screen($screen__s) {
        display: none;
    }

    .products.wrapper ~ .toolbar & {
        display: none;
    }
}

//
//  Pagination
//  ---------------------------------------------

.pages {
    display: none;

    .products.wrapper ~ .toolbar & {
        display: inline-block;
    }
}

//
//  Limiter
//  ---------------------------------------------

.limiter {
    display:none;
    float: right;
    margin-bottom: $indent__s;

    @include max-screen($screen__m) {
        display: none;
    }

    .products.wrapper ~ .toolbar & {
        display: none;
    }
    label {
        float: right;
        margin-left: $indent__xs;
    }
    .control {
        float: left;

        a {
            float: left;
            color: #97abc3;
            font-weight: 500;
            border-left: 1px solid #e7ecf2;
            padding: 0 $indent__s;

            @include max-screen($screen__xl) {
                padding: 0 $indent__xs;
            }

            &:first-child {
                border: 0;
            }
            &.active,
            &:hover {
                text-decoration: none;
                color: $primary__color;
            }
        }
    }
    .limiter-options {
        display: none;
    }
}

//
//  Sorter
//  ---------------------------------------------

.sorter {
    float: right;

    label{
        color: #6e7375;
        font-size: 11px;
        text-transform: uppercase;
    }


    .products.wrapper ~ .toolbar & {
        display: none;
    }
    .sorter-options {
        background: transparent;
        border: transparent;
        color: white;
        text-transform: uppercase;
        width: auto;
        font-size: 12px;
        padding: 0;
    }
    a{
        color: white;
    }
    .sorter-action {
        vertical-align: top;
        @include fa-icon();
        @extend .fas;
        margin-left: $indent__xs;
        font-size: 18px;

        span {
            display: none;
        }
        &:hover {
            text-decoration: none;
        }
        &:before {
            content: fa-content($fa-var-arrow-circle-up);
        }
        &.sort-desc {
            &:before {
                content: fa-content($fa-var-arrow-circle-down);
            }
        }
    }

}

//
//  View modes
//  ---------------------------------------------

.modes {
    display:none;
    float: right;
    margin-left: $indent__xl;
    margin-bottom: $indent__s;

    @include max-screen($screen__xl) {
        display: none;
    }
    .products.wrapper ~ .toolbar & {
        display: none;
    }
    .modes-label {
        float: left;
        font-weight: 300;
        margin-right: $indent__s;
    }
    .modes-mode {
        color: #d2d9e2;
        border-right: 1px solid #d2d9e2;
        float: left;
        padding: 0 10px;

        &:visited {
            color: #d2d9e2;
        }
        span {
            display: none;
        }
        &:before {
            @extend .fas;
            @include fa-icon();
        }
        &.mode-grid {
            &:before {
                content: fa-content($fa-var-grip-vertical);
            }
        }
        &.mode-list {
            &:before {
                content: fa-content($fa-var-grip-horizontal);
            }
        }
        &:last-child {
            border-right: 0;
        }
        &:hover,
        &.active {
            color: $primary__color;
        }
    }
}
