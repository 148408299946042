//
//  Filters
//  ---------------------------------------------
.sidebar.sidebar-additional {
    display: none;
}

.block.filter {
    margin-top: $indent__base;
    margin-bottom: $indent__xl;
    @include max-screen($screen__m) {
        margin-bottom: $indent__base;
    }

    .filter-title {
        display: none;
    }
}

.block-subtitle {
    display: inline-block;
    margin-bottom: $indent__s;
}

.filter-current {
    display: none;
    .item {
        margin-bottom: $indent__s;
    }

    .filter-label {
        display: block;
        font-weight: $font-weight__bold;

        &:after {
            content: ':';
        }
    }

    + .filter-actions {
        margin-bottom: $indent__base;
        .action.clear {
            @include lib-button();
            @include lib-link-as-button();
        }
    }
}

.filter-subtitle {
    display: none;
}

.filter-options {
    margin: 0;

    .filter-options-item {
        padding: $indent__m;
        margin-bottom: $indent__xs;

        .filter-options-title {
            font-size: 13px;
            text-transform: uppercase;
            font-weight: 500;
            word-break: break-all;
            margin-top: $indent__xs;

            @include max-screen($screen__m) {
                margin-top: 0;

                &:after {
                    @include fa-icon;
                    font-family: 'Font Awesome 5 Free';
                    content: fa-content($fa-var-plus-circle);
                    float: right;
                    font-weight: 900;
                    margin-top: 2px;
                }
                &.active {
                    &:after {
                        content: fa-content($fa-var-minus-circle);
                    }
                }
            }
        }
        .filter-options-content {
            display: none;
            margin-top: $indent__m;

            @include min-screen($screen__m) {
                margin-top: $indent__s;
                display: block !important;
            }
            .items {
                .item {
                    margin-bottom: $indent__s;

                    a {
                        display: block;
                        margin-left: 0;
                        font-size: 13px;
                        font-weight: 300;
                        padding-left: $indent__l;
                        background-image: url('../images/checkbox.png');
                        background-repeat: no-repeat;
                        background-position: left top;
                        line-height: 17px;
                        color: #ffffff;

                        label {
                            cursor: pointer;
                        }
                        &.am_shopby_link_selected,
                        &:hover {
                            text-decoration: none;
                            background-position: 0 -100px;
                        }
                        input {
                            display: none;
                        }
                    }
                }
            }
            .filter-count-label {
                @extend .abs-visually-hidden;
            }
        }
    }

    .count {
        @include lib-css(color, $text__color__muted);

        &:before {
            content: '(';
        }

        &:after {
            content: ')';
        }
    }
}

.items {
    @extend .abs-reset-list;
}

.filtered {
    .items {
        margin: 15px 0;
    }

    .item {
        margin-bottom: 6px;
        padding-left: 22px;
        position: relative;

        .label {
            font-weight: $font-weight__bold;
        }

        .action.remove {
            @extend .abs-remove-button-for-blocks;
            left: -6px;
            position: absolute;
            top: 0;
        }
    }

    & + .actions {
        margin-bottom: 35px;
    }
}
