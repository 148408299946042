// /**
//  * DISCLAIMER
//  *
//  * Do not edit or add to this file if you wish to upgrade Smile Elastic Suite to newer
//  * versions in the future.
//  *
//  *
//  * @category  Smile
//  * @package   Smile\ElasticsuiteCatalog
//  * @author    Aurelien FOUCRET <aurelien.foucret@smile.fr>
//  * @copyright 2016 Smile
//  * @license   Open Software License ("OSL") v. 3.0
//  */

.smile-es-range-slider {
    [data-role=from-label],
    [data-role=to-label] {
        font-size: 13px;
        font-weight: 300;
        margin-bottom: $indent__m;

        &[data-role=from-label] {
            float: left;
        }
        &[data-role=to-label] {
            float: right;
        }
    }
    [data-role=message-box] {
        font-size: 13px;
        flex: 1;
        line-height: 39px;

        &.empty {
            @include lib-form-validation-note();
            padding: 0;
        }
    }
    .actions-toolbar {
        display: flex;
        margin: $indent__base 0 0;

        @include max-screen($screen__l) {
            display: block;
        }
    }
    .ui-slider {
        position: relative;
        border: 1px solid #d5d5d5;
        background-color: #ffffff;
        border-radius: 0;
        margin: 0 $indent__m;
        clear: both;
        height: 7px;
        box-sizing: border-box;

        .ui-slider-range {
            position: absolute;
            background-color: $primary__color;
            height: 100%;
        }
        .ui-slider-handle {
            padding: 0;
            margin: -8px 0 0 -15px;
            border-radius: 7px;
            width: 31px;
            height: 21px;
            background: #ffffff;
            border: 1px solid #d5d5d5;
            box-sizing: border-box;
            background-image: url('../images/scroll.png');
            background-repeat: no-repeat;
            background-position: center;
            box-shadow: 0 1px 0.61px 0.39px rgba(0, 0, 0, 0.15);
            cursor: pointer;

            &:hover,
            &:focus {
                background-color: #d5d5d5;
            }
        }
    }
}

.filter {
    .block-subtitle {
        border-bottom: none;
        font-weight: bold;
    }

    .field.search {
        input[type="text"] {
            font-size: 85%;
        }
    }

    .actions {
        text-align: right;
        margin: 10px 0 0;

        div.secondary {
            display: inline-block;

            a {
                text-decoration: underline;
                cursor: pointer;
                color: $link__color;

                &:hover {
                    background: transparent;
                }

                &.show-more {
                    span:after {
                        content: " +";
                    }
                }

                &.show-less {
                    span:after {
                        content: " \2212";
                    }
                }
            }
        }
    }

    .filter-options {
        dt.filter-options-title[aria-expanded="true"]:after {
            content: "\e621"
        }
    }

    .no-results-message p {
        margin: 9px 10px 0;
        background-color: #ffeeee;
        padding: 5px;
        color: #aa0000;
    }
}

.smile-elasticsuite-autocomplete-result {
    .product-image-box {
        float: left;
        padding: 0 5px;
        width: 55px;
    }

    .product-shop {
        float: left;
        margin: 0 10px 0 0;
        padding: 0;
        white-space: normal;
        width: 60%;
    }

    .product-item {
        .price-box {
            margin: 5px 0;
        }
    }

    .category-mini-crumb {
        font-style: italic;
        display: block;
        margin-bottom: 0.2em;
        font-size: 85%;
        color: #929292;
    }

    .product-attribute-label {
        text-transform: uppercase;
        vertical-align: super;
        font-size: 63%;
        color: #777;
    }

    .price-box {
        span.old-price {
            display:block;
        }
    }
}


@include min-screen($screen__m) {
    .page-layout-1column {
        .filter-options {
            .filter {
                &-options {
                    &-content {
                        padding: $indent__s;
                    }
                }
            }
        }
    }
}
