.breadcrumbs {
    @include lib-breadcrumbs();

    @include max-screen($screen__m) {
        margin: $indent__s 0;
    }
    .item {
        text-transform: uppercase;
        a {
            font-weight: 300;
            color: #6e7375;
        }
        &:not(:last-child) {
            &:after {
                font-weight: bold;
                content: '/';
                color: #44484a;
                font-size: 16px;
                margin: -4px 6px 0;
            }
        }
        strong{
            color: #fff;
        }
    }
}
