//
// Pagination
// --------------------------------------------------

.pages {
    margin-top: $indent__l;

    .label {
        display: none;
    }
    .items {
        li {
            float: left;
            font-size: 13px;
            line-height: 29px;

            .page {
                display: block;
                color: #505153;
                padding: 0 $indent__base;
                border: 1px solid #505153;
                margin: 0 2px;
                font-weight: 500;

                .label {
                    display: none;
                }
                &:hover {
                    background-color: #d0dae6;
                    text-decoration: none;
                }
            }
            strong {
                background-color: transparent;
                border: 1px solid #ffffff !important;
            }
            .action {
                display: block;
                text-transform: none;
                color: #181c1e;
                background-color: #fff;
                border: 1px solid #fff;
                padding: 0 $indent__base;

                span {
                    display: none;
                }
                &.next {
                    margin-left: $indent__s;
                    font-size: 20px;
                    &:after {
                        @extend .fas;
                        @include fa-icon;
                        content: fa-content($fa-var-angle-right);
                        font-weight: 900;
                        vertical-align: -1px;
                    }
                }
                &.previous {
                    margin-right: $indent__s;
                    font-size: 20px;
                    &:before {
                        @extend .fas;
                        @include fa-icon;
                        content: fa-content($fa-var-angle-left);
                        font-weight: 900;
                        vertical-align: -1px;
                    }
                }
            }
        }
    }
}
